import Article from "../../components/Article/Article";
export default function Home() {
  return (
    <Article>
      <h1>
        class <white>Jonathan</white> () &#123;
        <br />
      </h1>
      <p>
        <br />
        Constructor() &#123;
        <br />
      </p>
      <h2 style={{ paddingLeft: "20px" }}>
         this.
        <strong>name</strong> =
        <strong>
          "<white>Jonathan Leverenz</white>"
        </strong>
        ,
        <br />
         this.
        <strong>title</strong> =
        <strong>
          "<white>Front-end Web Developer</white>"
        </strong>
      </h2>
      <p>&#125;</p>
    </Article>
  );
}
